/*
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-03-12 18:33:35
 * @LastEditors: zt zhoutao@ydmob.com
 * @LastEditTime: 2024-03-14 18:43:09
 * @FilePath: /mediatom-web/src/utils/dealNumber.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
function isNullorEmpty (str) {
  return str === '' || str === null || str === undefined || isNaN(str)
}

// 格式化小数点位数
export function toFixed (val, len) {
  if (isNullorEmpty(val)) return '--'
  len = len || 0
  return val && val !== 0 ? (Math.floor(Number(val) * Math.pow(10, len)) / Math.pow(10, len)).toFixed(len) : '0'
}
// 获取两数之间的随机数
export function getRandom (min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min)
}
// 格式化百分比, 为0显示0
export function toPercent (val, len) {
  if (isNullorEmpty(val)) return '--'
  return val && val !== 0 ? (Math.floor(Number(val) * Math.pow(10, len)) / Math.pow(10, len)).toFixed(len) + '%' : '0%'
}

// 格式化数据，自定义追尾字符串参数
// len：小数位长度,str：追尾字符串
// 示例：{{openPositionContract | format(2,' ETH')}}
export function formatNum (val, len, str) {
  if (val === 0) return 0 + str
  len = len || 0
  return val && val !== 0 ? (Math.floor(Number(val) * Math.pow(10, len)) / Math.pow(10, len)).toFixed(len) + str : '-'
}

// 数值过千加逗号，并保留几位小数
// num: 当前数字 len: 小数点长度 str: 字符前添加字符串
export function numFormat (num, len, str) {
  if (isNullorEmpty(num)) return '--'
  str = str || ''
  len = len || 2
  // 是否负数
  const isNegative = !(num >= 0)
  // 获取整数
  if (isNegative) num = Math.abs(num)
  const intNum = Math.trunc(num)
  // 获取小数部分
  let flotNum = ''
  if (num.toString().split('.').length > 1) {
    flotNum = num.toString().split('.')[1]
  }
  // 整数部分 千位加逗号
  let c = intNum && intNum !== 0 ? intNum.toString().replace(/(\d)(?=(\d{3})+$)/g, '$1,') : '0'
  // 小数部分，如果不是全0则保留，否则去掉
  if (flotNum && flotNum !== '' && parseFloat('0.' + flotNum) !== 0) {
    const temp = removeZeroOrDot(parseFloat('0.' + flotNum).toFixed(len))
    temp.split('.')[1] && (c += '.' + temp.split('.')[1])
  }
  // 是否负数
  if (isNegative) c = '-' + c
  return str + c
}

// 数值过千加逗号
export function numThousand (num, str) {
  if (isNullorEmpty(num)) return '--'
  str = str || ''
  const isNegative = !(num >= 0)
  // 是否负数
  if (isNegative) num = Math.abs(num)
  // 获取整数和小数部分
  const intNum = Math.trunc(num)
  let flotNum = ''
  if (num.toString().split('.').length > 1) {
    flotNum = num.toString().split('.')[1]
  }
  // 整数部分 千位加逗号
  // let c = num && num !== 0 ? Math.floor(Number(num)).replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : '0';
  let c = intNum && intNum !== 0 ? intNum.toString().replace(/(\d)(?=(\d{3})+$)/g, '$1,') : '0'
  // 是否负数
  if (isNegative) c = '-' + c + '.' + flotNum
  return str + c
}
export function removeZeroOrDot (str) {
  if (!str) return ''
  const regex = /(0|\.)$/
  if (regex.test(str)) {
    let newStr = str.replace(/0$/, '')
    newStr = str.replace(/.$/, '')
    return removeZeroOrDot(newStr)
  }
  return str
}
// 到 天
export const showDay = (time) => {
  // 时间戳转换
  if (!time) return '-'
  let date = null
  date = new Date(time)
  const Y = date.getFullYear()
  const M = date.getMonth() + 1
  const D = date.getDate()
  return `${Y}-${M > 9 ? M : '0' + M}-${D > 9 ? D : '0' + D}`
}
