<template>
  <a-modal
    v-model="advertisVisible"
    :title="title"
    @onCancel="handleCancel"
    @ok="handleSubmit"
    :width="840"
    dialogClass="advertisModal"
  >
    <div class="advertisModalWrapper">
      <a-form-model ref="ruleForm" class="plat_form" :model="formquery" v-bind="layout">
        <!-- 计划名称 -->
        <a-form-model-item
          :label="modificationType"
          v-if="modificationType === '计划名称'"
          prop="adPlanName"
          :rules="[{ required: true, message: '请输入名称', trigger: 'blur' }]"
        >
          <a-input placeholder="请输入名称" :maxLength="30" v-model.trim="formquery.adPlanName">
            <span slot="suffix">{{ formquery.adPlanName ? formquery.adPlanName.length : 0 }}/30</span>
          </a-input>
        </a-form-model-item>
        <!-- 广告组名称 -->
        <a-form-model-item
          :label="modificationType"
          v-if="modificationType === '广告组名称'"
          prop="adGroupName"
          :rules="[{ required: true, message: '请输入名称', trigger: 'blur' }]"
        >
          <a-input placeholder="请输入名称" :maxLength="30" v-model.trim="formquery.adGroupName">
            <span slot="suffix">{{ formquery.adGroupName ? formquery.adGroupName.length : 0 }}/30</span>
          </a-input>
        </a-form-model-item>
        <!-- 广告名称 -->
        <a-form-model-item
          :label="modificationType"
          v-if="modificationType === '广告名称'"
          prop="adName"
          :rules="[{ required: true, message: '请输入名称', trigger: 'blur' }]"
        >
          <a-input placeholder="请输入名称" :maxLength="30" v-model.trim="formquery.adName">
            <span slot="suffix">{{ formquery.adName ? formquery.adName.length : 0 }}/30</span>
          </a-input>
        </a-form-model-item>
        <!-- 限额 -->
        <a-form-model-item
          label="日限额"
          v-if="modificationType === '日限额'"
          prop="budget"
          :rules="[{ required: true, message: '请输入日限额', trigger: 'blur' }]"
        >
          <a-radio-group v-model="datetype" @change="callback">
            <a-radio-button value="a"> 不限 </a-radio-button>
            <a-radio-button value="b"> 自定义 </a-radio-button>
          </a-radio-group>
          <template v-if="datetype === 'b'">
            <a-input-number class="input" :min="1" :precision="0" v-model.trim="formquery.budget" />
            元/天
          </template>
        </a-form-model-item>
        <!-- 投放时间 -->
        <a-form-model-item
          :label="modificationType"
          v-if="modificationType === '投放时间'"
          :rules="[{ required: true, message: '请选择投放时间', trigger: 'blur' }]"
        >
          <a-radio-group v-model="timetype" @change="timecallback">
            <a-radio-button value="1"> 长期投放 </a-radio-button>
            <a-radio-button value="2"> 指定开始和结束日期 </a-radio-button>
          </a-radio-group>
          <template v-if="timetype === '1'">
            <div style="width: 250px; margin-top: 10px">
              开始日期:
              <a-date-picker
                class="m-r-10"
                :disabledDate="disabledDate"
                @change="handleChangeDate"
                :value="startDate"
              />
            </div>
          </template>
          <template>
            <a-range-picker
              class="m-r-10"
              style="width: 250px; margin-top: 10px"
              v-if="timetype === '2'"
              format="YYYY-MM-DD"
              :value="rangePickerDate"
              :placeholder="['开始日期', '结束日期']"
              @change="handleChangeTime"
              :disabledDate="disabledDates"
              :ranges="{
                今日: [initDate(0, 0).start, initDate(0, 0).end],
                昨日: [initDate(1, 1).start, initDate(1, 1).end],
                最近一周: [initDate(6, 0).start, initDate(6, 0).end],
                最近一个月: [initDate(30, 0).start, initDate(30, 0).end]
              }"
            />
          </template>
        </a-form-model-item>
      </a-form-model>
    </div>
  </a-modal>
</template>
<script>
import { updateAdplan, updateAdPlanGroupInfo, updateAdInfo } from '@/api/advertisement'
import mixDate from '@/mixins/initDate'
export default {
  mixins: [mixDate],
  data () {
    return {
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 14 }
      },
      formquery: {
        adPlanName: undefined
      },
      startDate: null,
      datetype: 'a',
      timetype: '1',
      budget: '',
      rangePickerDate: null,
      rules: {}
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: true
    },
    placeInfo: {
      default: () => ({}),
      type: Object
    },
    type: {
      type: String,
      default: ''
    },
    modificationType: {
      type: String,
      default: ''
    }
  },
  watch: {
    placeInfo: {
      handler (val) {
        this.formquery = JSON.parse(JSON.stringify(val))
      },
      deep: true,
      immediate: true
    },
    modificationType: {
      handler (val) {
        this.title = '修改' + val
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    advertisVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('modalCancel')
      }
    }
  },
  mounted () {
    // 初始化时间
    const { endDate, startDate } = this.initDate(0, 0)
    // isEdit true === 长期 ，false === 指定
    const isEdit = this.formquery.putEndDate === '' || false
    // 1 === 长期 2 === 指定
    this.timetype = isEdit ? '1' : '2'
    this.rangePickerDate = isEdit ? [endDate, startDate] : [this.formquery.putStartDate, this.formquery.putEndDate]
    this.startDate = isEdit ? this.formquery.putStartDate : startDate
    // budget === 10000000 不限 否则是自定义
    this.datetype = this.formquery.budget === 10000000 ? 'a' : 'b'
  },
  methods: {
    // 不可选时间
    disabledDates (current) {
      // 默认大于今天的时间
      return current && current < this.initDate(1, 1).end
    },
    disabledDate (current) {
      // Can not select days before today and today
      return current && current < this.initDate(1, 1).end
    },
    timecallback (e) {
      this.timetype = e.target.value
      const [endDate, startDate] = this.rangePickerDate
      if (e.target.value === '1') {
        this.formquery.startDate = this.startDate
        this.formquery.endDate = ''
      } else {
        this.formquery.startDate = startDate
        this.formquery.endDate = endDate
      }
    },
    callback (e) {
      this.datetype = e.target.value
      if (e.target.value === 'b') {
        this.formquery.budget = this.formquery.budget === 10000000 ? '' : this.formquery.budget
      }
    },
    handleCancel () {
      this.advertisVisible = false
    },
    handleChangeDate (date, dateString) {
      this.startDate = dateString
      this.formquery.startDate = dateString
      this.formquery.endDate = ''
    },
    // 选择时间
    handleChangeTime (date, dateString) {
      this.rangePickerDate = dateString
      const [startDate, endDate] = dateString
      this.formquery.startDate = startDate
      this.formquery.endDate = endDate
    },
    handleSubmit () {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          const budget = this.datetype === 'a' ? '10000000' : this.formquery.budget
          const data = {
            id: this.formquery.planId,
            name: this.formquery.adPlanName,
            budget: budget
          }
          let parmars = {
            id: this.formquery.adGroupId,
            name: this.formquery.adGroupName
          }
          if (this.modificationType === '投放时间') {
            const startDate = this.timetype === '1' ? this.startDate : this.formquery.startDate
            parmars = { ...parmars, ...{ startDate: startDate }, ...{ endDate: this.formquery.endDate } }
          } else if (this.modificationType === '日限额') {
            parmars = { ...parmars, ...{ dayBudget: budget } }
          }
          const queryData = {
            id: this.formquery.adId,
            name: this.formquery.adName,
            adGroupId: this.formquery.adGroupId
          }
          const post = {
            1: updateAdplan, // 广告计划更新
            2: updateAdPlanGroupInfo, // 广告组更新
            3: updateAdInfo // 广告更新
          }
          const query = {
            1: data,
            2: parmars,
            3: queryData
          }
          const resp = await post[this.type](query[this.type])
          if (resp.code === 200) {
            this.$message.success('修改成功!')
            this.$emit('getlist')
            this.advertisVisible = false
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep {
  .ant-modal {
    top: 28%;
  }
  .ant-modal-footer {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
    border-top: none;
    padding: 16px;
  }
  .ant-btn,
  .ant-btn-primary {
    width: 90px;
  }
  .ant-radio-button-wrapper {
    border-color: none;
  }
  .ant-input-affix-wrapper {
    background: #ffffff;
    border-radius: 5px;
    margin-left: 10px;
  }
  .input {
    width: 130px;
    margin-left: 10px;
    border-radius: 5px;
  }
  .ant-input {
    height: 32px;
  }
  .ant-radio-button-wrapper:first-child {
    background: #ffffff;
    border: 1px solid #e6e6e6;
    border-radius: 5px 0px 0px 5px;
  }
  .ant-radio-button-wrapper:last-child {
    background: #ffffff;
    border: 1px solid #e6e6e6;
    border-radius: 0px 5px 5px 0px;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
    border-color: none;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background: fade(@primary-color, 10%);
    border: 1px solid fade(@primary-color, 40%);
  }
}
.advertisModal,
.ant-modal {
  .advertisModalWrapper {
    min-height: 180px;
    padding: 10px 0;
    overflow-y: auto;
    border-radius: 5px;
    .ant-form {
      padding-top: 4%;
    }
  }
}
</style>
