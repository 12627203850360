export const stsOptions = [
  {
    label: '所有未删除',
    value: '4'
  },
  {
    label: '启用中',
    value: '0'
  },
  {
    label: '暂停中',
    value: '1'
  },
  {
    label: '广告组暂停',
    value: '2'
  },
  {
    label: '已删除',
    value: '3'
  }
]
export const auditStsOption = [
  {
    label: '所有创意',
    value: '3'
  },
  {
    label: '待审核',
    value: '0'
  },
  {
    label: '审核通过',
    value: '1'
  },
  {
    label: '审核未通过',
    value: '2'
  }
]
export const stsDesc = {
  label: '状态',
  prop: 'stsDesc',
  key: 'stsDesc',
  minWidth: 130,
  title: '状态',
  dataIndex: 'stsDesc'
}
export const basicAttributes = [
  {
    label: '广告名称',
    prop: 'adName',
    key: 'adName',
    minWidth: 260,
    checked: true,
    title: '广告名称',
    dataIndex: 'adName',
    disabled: true
  },
  { label: '状态', prop: 'stsDesc', key: 'stsDesc', minWidth: 130, title: '状态', dataIndex: 'stsDesc' },
  {
    label: '广告计划名称',
    prop: 'adPlanName',
    key: 'adPlanName',
    minWidth: 180,
    title: '广告计划名称',
    dataIndex: 'adPlanName'
  },
  {
    label: '广告组名称',
    prop: 'adGroupName',
    key: 'adGroupName',
    minWidth: 150,
    title: '广告组名称',
    dataIndex: 'adGroupName'
  }
]
export const disAttributes = [
  {
    label: '曝光量',
    prop: 'impress',
    key: 'impress',
    minWidth: 130,
    sortable: 'custom',
    checked: true,
    title: '曝光量',
    dataIndex: 'impress',
    disabled: true
  },
  {
    label: '点击量',
    prop: 'click',
    key: 'click',
    minWidth: 130,
    sortable: 'custom',
    title: '点击量',
    dataIndex: 'click'
  },
  {
    label: '点击率',
    key: 'clickRate',
    prop: 'clickRate',
    minWidth: 130,
    sortable: 'custom',
    title: '点击率',
    dataIndex: 'clickRate'
  },
  {
    label: '千次展示均价',
    prop: 'ecpm',
    key: 'ecpm',
    minWidth: 130,
    sortable: 'custom',
    title: '千次展示均价',
    dataIndex: 'ecpm'
  },
  {
    label: '点击均价',
    prop: 'ecpc',
    key: 'ecpc',
    minWidth: 130,
    sortable: 'custom',
    title: '点击均价',
    dataIndex: 'ecpc'
  },
  {
    label: '花费',
    prop: 'income',
    key: 'income',
    minWidth: 130,
    sortable: 'custom',
    title: '花费',
    dataIndex: 'income'
  }
]

export const arrAttributes = [
  {
    label: '广告名称',
    prop: 'adName',
    key: 'adName',
    minWidth: 260,
    checked: true,
    title: '广告名称',
    dataIndex: 'adName',
    disabled: true
  },
  { label: '状态', prop: 'stsDesc', key: 'stsDesc', minWidth: 130, title: '状态', dataIndex: 'stsDesc' },
  {
    label: '广告计划名称',
    prop: 'adPlanName',
    key: 'adPlanName',
    minWidth: 180,
    title: '广告计划名称',
    dataIndex: 'adPlanName'
  },
  {
    label: '广告组名称',
    prop: 'adGroupName',
    key: 'adGroupName',
    minWidth: 150,
    title: '广告组名称',
    dataIndex: 'adGroupName'
  },
  {
    label: '曝光量',
    prop: 'impress',
    key: 'impress',
    minWidth: 130,
    sortable: 'custom',
    checked: true,
    title: '曝光量',
    dataIndex: 'impress',
    disabled: true
  },
  {
    label: '点击量',
    prop: 'click',
    key: 'click',
    minWidth: 130,
    sortable: 'custom',
    title: '点击量',
    dataIndex: 'click'
  },
  {
    label: '点击率',
    key: 'clickRate',
    prop: 'clickRate',
    minWidth: 130,
    sortable: 'custom',
    title: '点击率',
    dataIndex: 'clickRate'
  },
  {
    label: '千次展示均价',
    prop: 'ecpm',
    key: 'ecpm',
    minWidth: 130,
    sortable: 'custom',
    title: '千次展示均价',
    dataIndex: 'ecpm'
  },
  {
    label: '点击均价',
    prop: 'ecpc',
    key: 'ecpc',
    minWidth: 130,
    sortable: 'custom',
    title: '点击均价',
    dataIndex: 'ecpc'
  },
  {
    label: '花费',
    prop: 'income',
    key: 'income',
    minWidth: 130,
    sortable: 'custom',
    title: '花费',
    dataIndex: 'income'
  }
]
export const innerColumns = [
  { label: '状态', prop: 'stsDesc', key: 'stsDesc', minWidth: 130, title: '状态', dataIndex: 'stsDesc' },
  {
    label: '广告计划名称',
    prop: 'adPlanName',
    key: 'adPlanName',
    minWidth: 180,
    title: '广告计划名称',
    dataIndex: 'adPlanName'
  },
  { label: '广告组名称', prop: 'adGroupName', key: 'adGroupName', minWidth: 150 },
  { label: '曝光量', prop: 'impress', key: 'impress', minWidth: 130, sortable: 'custom' },
  { label: '点击量', prop: 'click', key: 'click', minWidth: 130, sortable: 'custom' },
  { label: '点击率', key: 'clickRate', prop: 'clickRate', minWidth: 130, sortable: 'custom' },
  { label: '千次展示均价', prop: 'ecpm', key: 'ecpm', minWidth: 130, sortable: 'custom' },
  { label: '点击均价', prop: 'ecpc', key: 'ecpc', minWidth: 130, sortable: 'custom' },
  { label: '花费', prop: 'income', key: 'income', minWidth: 130, sortable: 'custom' }
]

// 根据一个数组的顺序排序另一个数组
export function sortList (sortList, dragArr) {
  const arr = dragArr.map((item) => {
    return item.key
  })
  sortList.sort((a, b) => {
    return arr.indexOf(a.prop) - arr.indexOf(b.prop)
  })
  return sortList
}
