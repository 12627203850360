import moment from 'moment'

export function getDuration () {
  const date1 = '2021-08-31'
  const date2 = moment(new Date())
  return moment.duration(moment(date2).diff(date1, 'day'))
}
export function getDurations () {
  const date1 = '2023-09-30'
  const date2 = moment(new Date())
  return moment.duration(moment(date2).diff(date1, 'day'))
}

export function getDays (startDateStr, endDateStr) {
  const start = Date.parse(startDateStr)
  const end = Date.parse(endDateStr)
  return (end - start) / 1000 / 60 / 60 / 24 + 1
}
