<template>
  <a-modal
    v-model="custTagsVisible"
    title="自定义指标"
    @onCancel="handleCancel"
    @ok="handleSubmit"
    dialogClass="cust_tags_modal"
    :width="700"
  >
    <div class="cust-tags-container">
      <div class="cust-tag-left">
        <div class="left-main">
          <template v-if="+type > 3">
            <div class="left-main-tags">
              <el-checkbox
                :indeterminate="datadeterminate"
                v-model="datacheckAll"
                @change="handleCheckAlldata(datacheckAll)"
                class="cust-tag-right-header"
              >
                数据指标
                <span style="margin-left: 5px">{{ isbascArrdata.length + '/' + dataAttributes.length }}</span>
              </el-checkbox>
              <div class="checkbox">
                <div class="checkbox_item" v-for="(attr, idex) in dataAttributes" :label="attr" :key="attr.id">
                  <el-checkbox @change="handleCheckdata(attr, idex)" :disabled="attr.disabled" v-model="attr.checked">{{
                    attr.title
                  }}</el-checkbox>
                </div>
              </div>
            </div>
            <div class="left-main-tags">
              <el-checkbox
                :indeterminate="mediaDatadeterminate"
                v-model="mediaDatacheckAll"
                @change="handleMediaCheckAlldata(mediaDatacheckAll)"
                class="cust-tag-right-header"
              >
                媒体指标
                <span style="margin-left: 5px">{{ mediaIsbascArrdata.length + '/' + mediaDataAttributes.length }}</span>
              </el-checkbox>
              <div class="checkbox">
                <div class="checkbox_item" v-for="(attr, idex) in mediaDataAttributes" :label="attr" :key="attr.id">
                  <el-checkbox @change="handleCheckdata(attr, idex)" :disabled="attr.disabled" v-model="attr.checked">{{
                    attr.title
                  }}</el-checkbox>
                </div>
              </div>
            </div>
          </template>

          <template v-else>
            <div class="left-main-tags">
              <el-checkbox
                :indeterminate="bascindeterminate"
                v-model="basccheckAll"
                @change="handleCheckAllBasc"
                class="cust-tag-right-header"
              >
                基础属性
                <span style="margin-left: 5px">{{ isbascArr.length + '/' + basicAttributes.length }}</span>
              </el-checkbox>
              <div class="checkbox">
                <div class="checkbox_item" v-for="(attr, index) in basicAttributes" :label="attr" :key="attr.id">
                  <el-checkbox @change="handleCheckBas(attr, index)" :disabled="attr.disabled" v-model="attr.checked">{{
                    attr.title
                  }}</el-checkbox>
                </div>
              </div>
            </div>
            <div class="left-main-tags">
              <el-checkbox
                :indeterminate="disindeterminate"
                v-model="discheckAll"
                @change="handleCheckAllDis(discheckAll)"
                class="cust-tag-right-header"
              >
                展示属性
                <span style="margin-left: 5px">{{ isbascArrdis.length + '/' + disAttributes.length }}</span>
              </el-checkbox>
              <div class="checkbox">
                <div class="checkbox_item" v-for="(attr, idex) in disAttributes" :label="attr" :key="attr.id">
                  <el-checkbox @change="handleCheckBas(attr, idex)" :disabled="attr.disabled" v-model="attr.checked">{{
                    attr.title
                  }}</el-checkbox>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div class="cust-tag-right">
        <div class="cust-tag-right-header">
          <span>已选字段（{{ dragArr.length }}） </span>
        </div>
        <m-vuedraggable v-model="dragArr" animation="350" chosen-class="chosen" class="drag_box">
          <transition-group>
            <div v-for="(e) in dragArr" :key="e.dataIndex" class="drag_item">
              <span class="drag_text">{{ e.title }} </span>
              <span class="el-icon-s-operation" />
            </div>
          </transition-group>
        </m-vuedraggable>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { mapState } from 'vuex'
import localDataName from '@/utils/localDataName'
export default {
  data () {
    return {
      dragArr: [], // 可以拖动操作的数据
      basccheckAll: false,
      discheckAll: false,
      datacheckAll: false,
      mediaDatacheckAll: false,
      disindeterminate: true,
      datadeterminate: true,
      mediaDatadeterminate: true,
      bascindeterminate: true
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    storageName: {
      default: '',
      type: String
    },
    basicAttributes: {
      // 已选属性
      default: () => [],
      type: Array
    },
    disAttributes: {
      // 已选属性
      default: () => [],
      type: Array
    },
    dataAttributes: {
      // 已选属性
      default: () => [],
      type: Array
    },
    mediaDataAttributes: {
      // 已选属性
      default: () => [],
      type: Array
    },
    sureArr: {
      // 已选属性
      default: () => [],
      type: Array
    },
    type: {
      default: '',
      type: String
    }
  },
  watch: {
    basicAttributes: {
      handler (val) {
        const arr = []
        val.map((item) => {
          if (item.checked) arr.push(item)
        })
        this.basccheckAll = arr.length === val.length
        this.bascindeterminate = arr.length > 0 && arr.length < val.length
        this.isbascArr = arr
      },
      deep: true,
      immediate: true
    },
    disAttributes: {
      handler (val) {
        const arr = []
        val.map((item) => {
          if (item.checked) arr.push(item)
        })
        this.discheckAll = arr.length === val.length
        this.disindeterminate = arr.length > 0 && arr.length < val.length
        this.isbascArrdis = arr
      },
      deep: true,
      immediate: true
    },
    dataAttributes: {
      handler (val) {
        const arr = []
        val.map((item) => {
          if (item.checked) arr.push(item)
        })
        this.datacheckAll = arr.length === val.length
        this.datadeterminate = arr.length > 0 && arr.length < val.length
        this.isbascArrdata = [...arr]
      },
      deep: true,
      immediate: true
    },
    mediaDataAttributes: {
      handler (val) {
        const arr = []
        val.map((item) => {
          if (item.checked) arr.push(item)
        })
        this.mediaDatacheckAll = arr.length === val.length
        this.mediaDatadeterminate = arr.length > 0 && arr.length < val.length
        this.mediaIsbascArrdata = [...arr]
      },
      deep: true,
      immediate: true
    }
  },
  mounted () {
    this.dragArr = JSON.parse(JSON.stringify(this.sureArr))
    this.sureArr.map((item) => {
      this.basicAttributes.map((item1) => {
        if (item.key === item1.key) item1.checked = true
      })
      this.disAttributes.map((item2) => {
        if (item.key === item2.key) item2.checked = true
      })
      this.dataAttributes.map((item3) => {
        if (item.dataIndex === item3.dataIndex) item3.checked = true
      })
      this.mediaDataAttributes.map((item4) => {
        if (item.dataIndex === item4.dataIndex) item4.checked = true
      })
    })
  },
  computed: {
    ...mapState({
      title: (state) => state.autoweb.title
    }),
    custTagsVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('modalCancel')
      }
    }
  },
  methods: {
    // 点击基础属性全选
    handleCheckAllBasc (val) {
      this.bascindeterminate = val
      const arr = this.basicAttributes.filter((item) => !item.disabled)
      this.basicAttributes.map((item) => {
        if (item.disabled) return
        item.checked = val
      })
      this.dragArr = val
        ? [...this.dragArr, ...arr]
        : this.dragArr.filter((item) => !arr.some((items) => item.key === items.key))
    },
    // 点击属性展示全选
    handleCheckAllDis (val) {
      this.disindeterminate = val
      const arr = this.disAttributes.filter((item) => !item.disabled)
      this.disAttributes.map((item) => {
        if (item.disabled) return
        item.checked = val
      })
      this.dragArr = val
        ? [...this.dragArr, ...arr]
        : this.dragArr.filter((item) => !arr.some((items) => item.key === items.key))
    },
    handleCheckAlldata (val) {
      this.datadeterminate = val
      this.dataAttributes.map((item) => {
        item.checked = val
      })
      this.dragArr = [...this.dataAttributes, ...this.mediaDataAttributes.filter((item) => item.checked)]
    },
    handleMediaCheckAlldata (val) {
      this.mediaDatadeterminate = val
      this.mediaDataAttributes.map((item) => {
        item.checked = val
      })
      this.dragArr = [...this.mediaDataAttributes, ...this.dataAttributes.filter((item) => item.checked)]
    },
    // 点击选中/取消属性
    handleCheckBas (val, i) {
      var idx = this.dragArr.findIndex((item) => item.key === val.key)
      val.checked ? this.dragArr.push(val) : this.dragArr.splice(idx, 1)
    },
    handleCheckdata (val, i) {
      var idx = this.dragArr.findIndex((item) => item.dataIndex === val.dataIndex)
      val.checked ? this.dragArr.push(val) : this.dragArr.splice(idx, 1)
    },
    handleCancel () {
      this.custTagsVisible = false
    },
    handleSubmit () {
      if (this.dragArr.length < 5) {
        return this.$message.warning('至少选中五个指标')
      }
      this.custTagsVisible = false
      const list = {
        1: localDataName.adPlanStorageName,
        2: localDataName.adGroupStorageName,
        3: localDataName.adCreativeStorageName,
        4: localDataName.mediasettleAppLocalName,
        5: localDataName.mediasettleAdvLocalName,
        6: localDataName.mediasettlePlatLocalName
      }
      localStorage.setItem(list[this.type], JSON.stringify(this.dragArr))
      this.$emit('changeCustTags', this.dragArr)
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep {
  .ant-modal-body {
    background-color: #eee;
  }

  .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #4d4d4d;
  }

  .el-checkbox__label {
    color: #4d4d4d;
    font-size: 14px;
    font-weight: 400;
  }
}

.cust-tags-container {
  border-radius: 5px;
  display: flex;

  .cust-tag-left,
  .cust-tag-right {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    height: 50vh;
    overflow-y: auto;
  }

  .cust-tag-left {
    width: 70%;
    border-radius: 5px;
    margin-right: 10px;
    border: 0px solid #fff;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      box-shadow: inset 0 0 5px fade(@primary-color, 3%);
      background: fade(@primary-color, 20%);
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px fade(@primary-color, 3%);
      border-radius: 0;
      background: rgba(0, 0, 0, 0.1);
    }

    .left-main {
      flex: 1;
      display: flex;
      flex-direction: column;
      padding: 20px;

      .left-main-tags {
        border: 1px solid #f2f2f2;
        margin-bottom: 20px;
        border-radius: 5px;

        .checkbox {
          width: 100%;
          position: relative;
          display: flex;
          flex-wrap: wrap;
          margin: 10px 0;
          padding: 0 10px;

          .el-checkbox {
            margin-right: 0;
          }

          .checkbox_item {
            width: 33%;
            padding: 10px;
            height: 40px;

            // line-height: 40px;
            input {
              font-size: 12px;
              cursor: pointer;
              width: 20px;
              height: 20px;
              vertical-align: middle;
              border-radius: 5px !important;
              margin-right: 10px;
            }
          }
        }
      }
    }
  }

  .cust-tag-right {
    width: calc(30% - 10px);
    border-radius: 5px;
    border: 1px solid #f2f2f2;

    .drag_box {
      flex: 1;
      background-color: #fff !important;
      overflow: auto;
      height: 100%;
      padding: 10px 0;

      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        box-shadow: inset 0 0 5px fade(@primary-color, 3%);
        background: fade(@primary-color, 20%);
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px fade(@primary-color, 3%);
        border-radius: 0;
        background: rgba(0, 0, 0, 0.1);
      }
    }

    .drag_item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 92%;
      height: 35px;
      margin: 0 auto;
      padding: 0 10px;
      background-color: #fff;
      margin-top: 5px;
      cursor: move;

      .drag_text {
        flex: 1;
        text-align: left;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
      }

      .el-icon-s-operation {
        font-size: 18px;
        width: 42px;
        text-align: center;
        color: @primary-color;
      }
    }
  }
}

.cust-tag-right-header {
  box-sizing: border-box;
  height: 40px;
  line-height: 40px;
  width: 100%;
  background: #fafafa;
  border-bottom: 1px solid #f2f2f2;
  padding: 0 20px;
  border-radius: 5px 5px 0px 0px;
}
</style>
