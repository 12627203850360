import { readerFile } from './dealxlsx'
import { message } from 'ant-design-vue'

export default async function excel (res, name = '未命名.xls') {
  const transformData = await readerFile(res)
  try {
    const obj = JSON.parse(transformData)
    const msg = obj.rspMsg || obj.msg
    message.warning(msg)
  } catch (e) {
    const blobUrl = window.URL.createObjectURL(res)
    const a = document.createElement('a')
    a.download = name
    a.href = blobUrl
    a.click()
    URL.revokeObjectURL(blobUrl)
  }
}
