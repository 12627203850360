<template>
  <div class="advertisingPlan_container">
    <!-- 搜索、操作按钮 -->
    <div class="search">
      <div class="serachBox">
        <a-range-picker
          allowClear
          class="m-r-10 m-b-10"
          style="width: 250px"
          format="YYYY-MM-DD"
          :value="rangePickerDate"
          :placeholder="['开始日期', '结束日期']"
          @change="handleChangeTime"
          :disabledDate="disabledDate"
          :ranges="{
            今日: [initDate(0, 0).start, initDate(0, 0).end],
            昨日: [initDate(1, 1).start, initDate(1, 1).end],
            最近一周: [initDate(6, 0).start, initDate(6, 0).end],
            最近一个月: [initDate(30, 0).start, initDate(30, 0).end]
          }"
        >
        </a-range-picker>
        <a-select class="m-r-10" style="width: 200px" v-model="searchFormData.sts" placeholder="请选择广告状态">
          <a-select-option v-for="pitem in stsOptions" :key="pitem.id" :value="pitem.value">
            {{ pitem.label }}
          </a-select-option>
        </a-select>
        <a-select
          class="m-r-10"
          style="width: 200px"
          v-model="searchFormData.auditSts"
          placeholder="请选择创意审核状态"
        >
          <a-select-option v-for="aduitem in auditStsOption" :key="aduitem.id" :value="aduitem.value">
            {{ aduitem.label }}
          </a-select-option>
        </a-select>
        <a-input
          placeholder="请输入广告计划名称"
          class="m-r-10"
          v-model.trim="searchFormData.planName"
          style="width: 300px"
          :allowClear="true"
        />
        <a-input
          placeholder="请输入广告组名称"
          class="m-r-10"
          v-model.trim="searchFormData.groupName"
          style="width: 300px"
          :allowClear="true"
        />
        <a-input
          placeholder="请输入广告名称"
          class="m-r-10"
          v-model.trim="searchFormData.adName"
          style="width: 300px"
          :allowClear="true"
        />
        <a-button type="primary" class="btn" @click="handleSerach">查询</a-button>
      </div>
      <div class="allianceSerachForm m-t-20">
        <div>
          <a-button class="m-r-10" type="primary" @click="handleShowModel">新建广告</a-button>
          <el-popover placement="bottom-start" v-model="popoverVisible">
            <div style="padding: 5px">
              <a-radio style="font-weight: bold; color: #333" v-model="radioValue">状态</a-radio>
              <div style="display: flex; margin-top: 10px">
                <div v-for="item of batchOption" :key="item.id">
                  <el-tooltip
                    effect="dark"
                    :disabled="!btnDisabled"
                    content="不能对选中的广告计划执行该操作"
                    placement="right-start"
                  >
                    <span>
                      <el-button plain class="btnHover" @click="batchOprate(item)" :disabled="btnDisabled">{{
                        item.name
                      }}</el-button>
                    </span>
                  </el-tooltip>
                </div>
              </div>
            </div>
            <a-button
              slot="reference"
              :disabled="!multipleSelection.length > 0"
              class="m-r-10"
            >批量操作 <a-icon
              type="down"
            /></a-button>
          </el-popover>
        </div>
        <div class="serachBox">
          <a-button class="m-r-10 btnColor" @click="handleClickCustTagsBtn">自定义列表</a-button>
          <a-button @click="exportReport" :loading="downLoading" class="btnColor">导出列表</a-button>
        </div>
      </div>
    </div>
    <!-- 表格 -->
    <a-spin :spinning="spinLoading" class="sonfig_tables m-t-20">
      <el-table
        row-key="adId"
        :data="data"
        style="width: 100%"
        ref="multipleTable"
        :cell-class-name="cellClass"
        @selection-change="handleSelectionChange"
        @cell-mouse-enter="handleMouseEnter"
        @cell-mouse-leave="handleMouseLeave"
        @sort-change="sortChange"
        :expand-row-keys="expands"
        :row-class-name="rowClass"
        border
      >
        <!-- selection -->
        <el-table-column type="selection" :selectable="selectable" width="60" align="center" fixed="left"/>
        <!-- children  -->
        <el-table-column prop="children" fixed="left" type="expand" width="1">
          <template slot-scope="scope">
            <el-table
              ref="subTableData"
              style="width: 100%"
              :show-header="false"
              :loading="subloading"
              :data="scope.row.children"
              v-if="scope.row.children.length > 0"
              border
            >
              <!-- 占位 -->
              <el-table-column width="60" fixed="left"> </el-table-column>
              <!-- 广告名称 -->
              <el-table-column label="广告名称" width="260" fixed="left" prop="adName">
                <!-- eslint-disable vue/no-template-shadow -->
                <template slot-scope="scope">
                  <div class="creative_item_wrapper">
                    <div v-if="scope.row.creativityType === '1'" class="el_image_box">
                      <el-image
                        class="elImage"
                        style="height: 98px; max-width: var(--ad--creative--width)"
                        :src="adplanResourceUrl + scope.row.creativeUrl"
                        :preview-src-list="[adplanResourceUrl + scope.row.creativeUrl]"
                      >
                      </el-image>
                      <div class="modal">
                        <a-icon
                          class="deleteIcon"
                          type="eye"
                          @click="() => handlePreviewImg(adplanResourceUrl + scope.row.creativeUrl)"
                        />
                      </div>
                    </div>
                    <video
                      v-if="scope.row.creativityType === '2'"
                      :autoplay="false"
                      :src="adplanResourceUrl + scope.row.creativeUrl"
                      controls
                      style="width: 100%; height: 100px"
                    ></video>
                  </div>
                </template>
              </el-table-column>
              <!-- 开关 -->
              <el-table-column label="开关" fixed="left" prop="sts">
                <!-- eslint-disable vue/no-template-shadow -->
                <template slot-scope="scope">
                  <a-switch
                    v-show="scope.row.sts !== '3'"
                    size="small"
                    @change="handleChildCheckSts(scope.row)"
                    :checked="scope.row.sts !== '1'"
                  ></a-switch>
                </template>
              </el-table-column>
              <!-- 操作 -->
              <el-table-column label="操作" width="120" fixed="left" prop="action">
                <!-- eslint-disable vue/no-template-shadow -->
                <div class="action-slot" slot-scope="scope" v-show="scope.row.sts !== '3'">
                  <span class="btn" @click="handleEdit(scope.row)"> 编辑</span>
                  <span class="line">|</span>
                  <span
                    class="btn"
                    @click="handlChildeDetle(scope.row)"
                  >删除</span
                  >
                </div>
              </el-table-column>
              <el-table-column
                v-for="(item, indnx) in innerColumns"
                :key="indnx + Math.random()"
                :prop="item.prop"
                :width="item.width"
                :fixed="item.fixed"
                :type="item.type"
                :min-width="item.minWidth"
                :label="item.label"
              >
                <template slot-scope="scope">
                  <!-- 0: 投放中  1: 已暂停  2: 广告组暂停 3：已删除  4：所有未删除 -->
                  <!-- 暂停 = 红色 启用中 = 绿色  其他 = 都是灰色 -->
                  <span v-if="scope.column.label === '状态' || scope.column.label === '点击率'">
                    <span :class="[getColors(scope.row)]" v-if="scope.column.label === '状态'">{{
                      scope.row.stsDesc
                    }}</span>
                    <span v-else>{{ scope.row.clickRate }} %</span>
                  </span>
                  <span v-else>{{ scope.row[item.prop] }}</span>
                </template>
              </el-table-column>
            </el-table>
            <el-empty class="noData" v-else description="暂无数据！"></el-empty>
          </template>
        </el-table-column>
        <!-- 广告名称 -->
        <el-table-column label="广告名称" width="260" fixed="left" prop="adName">
          <template slot-scope="scope">
            <span v-if="scope.row.types === 'total'">
              <span>{{ scope.row.adName }}</span>
            </span>
            <span v-else class="fontColor">
              <i v-show="scope.row.showIcon" @click="handleChange(scope.row)" class="el-icon-caret-right m-r-10" />
              <i v-show="!scope.row.showIcon" @click="handleChangeOff(scope.row)" class="el-icon-caret-bottom m-r-10" />
              <span class="m-r-10">{{ scope.row.adName }}</span>
              <i
                v-show="scope.row.showEditIcon && scope.row.sts !== '3'"
                class="el-icon-edit"
                @click="editName(scope.row, '广告名称')"
              ></i>
            </span>
          </template>
        </el-table-column>
        <!-- 开关 -->
        <el-table-column label="开关" fixed="left" prop="sts">
          <template v-if="scope.row.types !== 'total' && scope.row.sts !== '3'" slot-scope="scope">
            <a-switch size="small" @change="handleCheckSts(scope.row)" :checked="scope.row.sts !== '1'"></a-switch>
          </template>
        </el-table-column>
        <!-- 操作 -->
        <el-table-column label="操作" width="120" fixed="left" prop="action">
          <template slot-scope="scope">
            <div class="action-slot" v-if="scope.row.types !== 'total' && scope.row.sts !== '3'">
              <span class="btn" @click="handleEdit(scope.row)"> 编辑</span>
              <span class="line">|</span>
              <span class="btn" @click="handleDetle(scope.row)">删除</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          v-for="item in innerColumns"
          :key="item.adId"
          :prop="item.prop"
          :width="item.width"
          :min-width="item.minWidth"
          :fixed="item.fixed"
          :type="item.type"
          :slot="item.slot"
          :sortable="item.sortable"
          :label="item.label"
          :sort-orders="['descending', 'ascending', null]"
        >
          <template slot-scope="scope">
            <!-- 暂停 = 红色 启用中 = 绿色  其他 = 都是灰色 -->
            <span v-if="scope.column.label === '状态' || scope.column.label === '点击率'">
              <span :class="[getColor(scope.row)]" v-if="scope.column.label === '状态'">{{ scope.row.stsDesc }}</span>
              <span v-else>{{ scope.row.clickRate }} %</span>
            </span>
            <span v-else>{{ scope.row[item.prop] }}</span>
          </template>
        </el-table-column>
      </el-table>
      <m-empty v-if="data.length == 1" style="height: 70vh; width: 100%">
        <template #description>暂无数据！</template>
      </m-empty>
      <a-pagination
        class="pagination"
        show-size-changer
        :current="querData.page"
        :total="total"
        :show-total="(total) => `共 ${total} 条`"
        @change="handleCurrentPage"
        @showSizeChange="handleChangePageSize"
        :pageSizeOptions="['10', '20', '40', '50', '80', '100']"
      />
    </a-spin>
    <a-modal :visible="previewVisibleModel" :footer="null" @cancel="handleCanPreview">
      <img alt="example" style="width: 100%; max-height: 600px" :src="previewImage" />
    </a-modal>
    <!-- 修改弹窗 -->
    <Model
      v-if="advertisVisible"
      :placeInfo="placeInfo"
      type="3"
      :modificationType="modificationType"
      :visible="advertisVisible"
      @getlist="getlist"
      @modalCancel="advertisVisible = false"
    />
    <!-- 自定义 -->
    <CustomListModal
      v-if="custTagsVisible"
      type="3"
      :visible="custTagsVisible"
      :disAttributes="disAttributes"
      :basicAttributes="basicAttributes"
      :sureArr="allcheckArr"
      @modalCancel="custTagsVisible = false"
      @changeCustTags="changeCustTags"
    />
    <!-- 批量操作删除提示弹窗 -->
    <a-modal v-model="popconfirmVisible" title="提示" :confirm-loading="confirmLoading" @ok="okDetle">
      <div class="batchModel">
        <a-icon class="icon" type="exclamation-circle" />
        <p v-if="batchData.operationObject === 3">删除广告将同步删除该广告下的所有创意，且无法恢复，是否仍要删除?</p>
        <p v-if="batchData.operationObject === 4">删除创意无法恢复，是否仍要删除？</p>
      </div>
    </a-modal>
  </div>
</template>
<script>
// creativeList
import { adPagerList, batchOperationSts, updateAdInfo, creativeList } from '@/api/advertisement'
import Steps from '../components/Steps'
import Model from '../component/nameModel'
import CustomListModal from '@/components/common/CustomList'
import {
  stsOptions,
  auditStsOption,
  innerColumns,
  arrAttributes,
  basicAttributes,
  disAttributes,
  sortList
} from './list'
// import { clearName, clearCode } from '../AdvertisingGroup/list.js'
import mixDate from '@/mixins/initDate'
import { showDay } from '@/utils/dealNumber'
import request from '@/utils/request'
import excel from '@/utils/excel'
import { mapGetters } from 'vuex'
import { getDuration } from '@/utils/duration'
import localDataName from '@/utils/localDataName'
export default {
  name: 'Advertisement',
  components: { Steps, Model, CustomListModal },
  mixins: [mixDate],
  data () {
    return {
      adCreativeStorageName: localDataName.adCreativeStorageName,
      expandKeys: [],
      spinLoading: false,
      searchFormData: {
        auditSts: '3',
        sts: '4'
      },
      innerColumns,
      stsOptions,
      auditStsOption,
      disAttributes,
      basicAttributes,
      arrAttributes,
      sureArrs: [],
      radioValue: true,
      data: [], // list data
      querData: {
        page: 1,
        limit: 10
      },
      popoverVisible: false, // 批量操作弹窗
      batchData: {
        // 批量操作参数
        sts: '',
        idList: [], // 批量操作选中的idlist
        operationObject: 3 // 1: 计划 2: 广告组 3: 广告 4: 素材
      },
      batchOption: [
        // 批量操作弹窗操作项
        { id: '0', name: '启用' },
        { id: '1', name: '暂停' },
        { id: 'D', name: '删除' }
      ],
      popconfirmVisible: false, // 删除确认弹窗
      total: 0,
      custTagsVisible: false, // 自定义指标弹窗
      rangePickerDate: null, // 日期
      advertisVisible: false, // 修改名称、限额弹窗
      placeInfo: {}, // 修改名称、限额弹窗传参
      modificationType: '', // 编辑 计划名称  限额
      allcheckArr: [],
      multipleSelection: [], // 多选的数据
      downLoading: false,
      expands: [],
      record: {},
      subloading: false,
      confirmLoading: false,
      previewVisibleModel: false,
      previewImage: []
    }
  },
  watch: {},
  computed: {
    ...mapGetters(['adplanResourceUrl']),
    btnDisabled () {
      return this.multipleSelection.some((item) => item.sts === '3') // 若选中列表中有已删除状态，则不能批量操作，批量弹窗按钮置灰
    }
  },
  created () {
    const { endDate, startDate } = this.initDate(0, 0)
    this.rangePickerDate = [startDate, endDate]
    this.querData.dateStart = startDate
    this.querData.dateEnd = endDate
    const { adGroupId = '' } = this.$route.query
    if (adGroupId !== '') {
      this.querData.adGroupId = adGroupId
    }
    this.getlist()
    this.initTargetArr()
  },
  methods: {
    handleCanPreview () {
      this.previewVisibleModel = false
    },
    handlePreviewImg (src) {
      this.previewVisibleModel = true
      this.previewImage = src
    },
    // 状态颜色判断
    getColor (record) {
      if (record.stsDesc === '投放已结束') return 'grey'
      return record.sts === '1' ? 'red' : record.sts === '0' ? 'green' : 'grey'
    },
    // 创意列表状态
    getColors (record) {
      if (record.stsDesc === '待审核' || record.stsDesc === '审核未通过') return 'grey'
      return record.sts === '1' ? 'red' : record.sts === '0' ? 'green' : 'grey'
    },
    // 鼠标移入
    handleMouseEnter (row) {
      row.showEditIcon = true
    },
    // 鼠标移出
    handleMouseLeave (row) {
      row.showEditIcon = false
    },
    // 多选 表格
    handleSelectionChange (val, row) {
      this.multipleSelection = val
      this.batchData.idList = val.map((item) => {
        return item.adId
      })
    },
    // 导出表格接口函数
    downloadReport (query) {
      return request.post('/admanage/downloadAdReport', query, {
        responseType: 'blob'
      })
    },
    // 导出报表
    async exportReport () {
      this.downLoading = true
      const codesList = []
      const namesList = []
      this.innerColumns.map((item) => {
        namesList.push(item.label)
        codesList.push(item.key)
      })
      const { dateStart, dateEnd, sortBy = '', sortOrder = '' } = this.querData || {}
      const { sts, planName = '', auditSts = '', groupName = '', adName = '' } = this.searchFormData || {}
      const parmars = {
        dateStart,
        dateEnd,
        sortBy,
        sortOrder,
        codeList: [...['adName'], ...codesList],
        nameList: [...['广告名称'], ...namesList],
        sts,
        auditSts,
        groupName,
        planName,
        adName
      }
      try {
        const res = await this.downloadReport(parmars)
        await excel(res, `广告计划列表-${showDay(new Date())}.xls`)
        this.downLoading = false
      } catch (e) {
        console.log(e, '导出excel错误信息')
      }
    },
    // 根据某个属性，添加样式，隐藏复选框
    cellClass (row, index) {
      if (row.row.types === 'total') {
        return 'myCell'
      }
    },
    // 根据某个属性，添加样式，隐藏点击子列表的按钮
    rowClass (row) {
      return 'row-expand-cover'
    },
    selectable (row, index) {
      // 为true的时候可以选，为false则不可选择
      return row.types !== 'total'
    },
    // 获取tablist
    getlist () {
      this.adPlanPagerFn({
        ...this.querData,
        ...this.searchFormData
      })
    },
    // 获取指标列表数组
    initTargetArr () {
      const arr =
        (localStorage.getItem(this.adCreativeStorageName) &&
          JSON.parse(localStorage.getItem(this.adCreativeStorageName) || [])) ||
        []
      const ifArr = localStorage.getItem(this.adCreativeStorageName)
      /***
       * arr 代表自定义数组有值
       * allcheckArr == 回显给自定义弹窗右侧的数组
       * innerColumns == 列表展示
       * 如缓存中有 allcheckArr == allcheckArr； 没有 == arrAttributes
       * 如缓存中有arr，this.innerColumns == arr去除adName，没有展示 == this.innerColumns
       ***/
      this.allcheckArr = ifArr ? arr : this.arrAttributes
      const arrCopy = JSON.parse(JSON.stringify(arr))
      this.innerColumns = ifArr ? arrCopy.filter((item) => item.key !== 'adName') : this.innerColumns
    },
    // 自定义指标
    handleClickCustTagsBtn () {
      this.custTagsVisible = true
    },
    // 自定义弹窗保存后触发改事件
    changeCustTags (dragArr, targetArr) {
      this.allcheckArr = dragArr
      // copy一份自定义弹窗里修改后的arrCopy数组
      const arrCopy = JSON.parse(JSON.stringify(dragArr))
      // 去除固定列的adName
      const arr = arrCopy.filter((item) => item.key !== 'adName')
      // 根据筛选出innerColumns 中 一致的 arrCopy
      const arrFilter = innerColumns.filter((item) => arr.some((items) => item.key === items.key))
      // 根据arrCopy的顺序，排列innerColumns顺序
      this.innerColumns = sortList(arrFilter, arr)
    },
    // 点击批量操作弹窗操作按钮
    batchOprate (data) {
      this.batchData.sts = data.id
      this.popoverVisible = false // 操作弹窗
      if (data.id === 'D') {
        this.popconfirmVisible = true // 删除提示弹窗
        return
      }
      this.batchOperationSts()
    },
    // 删除弹窗确定
    okDetle () {
      if (this.batchData.operationObject === 4) {
        this.switchStatus()
      } else {
        this.batchOperationSts()
      }
    },
    // 批量操作接口
    async batchOperationSts (type) {
      const res = await batchOperationSts(this.batchData)
      if (res.code === 200) {
        this.$message.success('修改成功')
        this.querData.sortOrder = ''
        this.querData.sortBy = ''
        this.getlist()
        this.multipleSelection = []
        this.popconfirmVisible = false
      }
    },
    // 点击广告列表删除
    handleDetle (record) {
      this.popconfirmVisible = true
      this.batchData = {
        sts: 'D',
        idList: [record.adId],
        operationObject: 3
      }
    },
    // 删除创意子列表调接口
    async switchStatus () {
      const res = await batchOperationSts(this.batchData)
      if (res.code === 200) {
        this.$message.success('删除成功')
        // 取消全选
        this.multipleSelection = []
        // 弹窗消失
        this.popconfirmVisible = false
        // 掉素材列表接口
        this.handleChange(this.record)
      }
    },
    // 点击创意子列表删除
    handlChildeDetle (record) {
      // 展示删除弹窗
      this.popconfirmVisible = true
      this.batchData = {
        sts: 'D',
        idList: [record.creativeId],
        operationObject: 4
      }
      this.record = record
    },
    // 删除提示弹窗
    handleCancel () {
      this.popconfirmVisible = false
    },
    // 点击批量操作
    batchOperation () {
      this.popoverVisible = true
    },
    // 列表字段排序
    sortChange (column) {
      const { prop, order } = column
      // 0=限额，1=曝光量，2=点击量，3=点击率，4=千次展示均价，5=点击均价，6=花费
      const list = {
        budget: 0,
        impress: 1,
        click: 2,
        clickRate: 3,
        ecpm: 4,
        ecpc: 5,
        income: 6
      }
      const status = {
        descending: 'desc', // 降序
        ascending: 'asc' // 升序
      }
      this.querData.sortOrder = status[order]
      this.querData.sortBy = list[prop]
      this.getlist()
    },
    // 点击图标 编辑名称/限额
    editName (record, prop) {
      // prop ==> adPlanName === 名称  budget === 编辑限额
      this.placeInfo = record
      this.modificationType = prop
      this.advertisVisible = true
    },
    // 点击收起列表
    handleChangeOff (row) {
      // expands 展开列表的key=adId，点击收起从数组中移除adId
      this.expands = this.expands.filter((item) => item !== row.adId)
      this.data[this.data.findIndex((val) => val.adId === row.adId)].children = []
      this.data[this.data.findIndex((val) => val.adId === row.adId)].showIcon = true
      this.$refs.multipleTable.toggleRowExpansion(row, false) // 关闭当前行
    },
    // 点击展开列表
    async handleChange (row) {
      // expands 展开列表的key=adId
      this.expands.push(row.adId)
      const { adName = '', adPlanName = '', adGroupName = '', adId = '' } = row
      const { sts = '', auditSts = '' } = this.searchFormData
      this.subloading = true
      const res = await creativeList({
        adName,
        planName: adPlanName,
        groupName: adGroupName,
        adId: adId,
        auditSts,
        sts,
        dateStart: this.rangePickerDate[0],
        dateEnd: this.rangePickerDate[1]
      })
      const index = this.data.findIndex((val) => val.adId === row.adId)
      if (res.code === 200 && res.data) {
        this.subloading = false
        this.data[index].showIcon = false
        this.$set(this.data[index], 'children', res.data)
        this.$refs.multipleTable.toggleRowExpansion(row, true) // 展开当前行
      }
    },
    // 获取tablist
    async adPlanPagerFn (data) {
      const res = await adPagerList(data)
      this.spinLoading = true
      if (res.code === 200 && res.data) {
        const tempData = res.data.items || []
        tempData.length !== 0 &&
          tempData.forEach((item, index) => {
            item.showIcon = true
            item.showEditIcon = false
            item.children = []
          })
        const obj = {
          types: 'total',
          adName: '总计：' + res.data.total + '条记录'
        }
        this.expands = [] // 刷新页面将打开列表清空
        this.data = [...[{ ...obj, ...res.data.aggregate }], ...tempData]
      }
      this.spinLoading = false
      this.total = res.data && res.data.total
    },
    // 点击开关
    handleCheckSts (record) {
      const stss = record.sts === '1' ? 0 : 1
      const { adId } = record
      this.updateAdPlanStsFn({ id: adId, sts: stss })
    },
    // 点击创意子列表开关
    async handleChildCheckSts (record) {
      const stss = record.sts === '1' ? 0 : 1
      const { creativeId } = record
      const data = {
        sts: stss,
        idList: [creativeId],
        operationObject: 4
      }
      const res = await batchOperationSts(data)
      if (res.code === 200) {
        this.$message.success('修改成功')
        this.handleChange(record)
      }
    },
    // 广告开关按钮修改
    async updateAdPlanStsFn (data) {
      const res = await updateAdInfo(data)
      if (res.code === 200) {
        this.$message.success('修改成功')
        // 清空排序
        this.querData.sortOrder = ''
        this.querData.sortBy = ''
        this.getlist()
      }
    },
    // 列表编辑
    async handleEdit (record) {
      const routeUrl = this.$router.resolve({
        name: 'editadsteps',
        query: {
          steps: 3,
          adId: record.adId
        }
      })
      window.open(routeUrl.href, '_blank')
    },
    // 创意列表编辑
    async handleChildEdit (record) {
    },
    // 新建广告
    handleShowModel (type, record) {
      const routeUrl = this.$router.resolve({
        name: 'addadsteps',
        query: {
          steps: 1
        }
      })
      window.open(routeUrl.href, '_blank')
    },
    // 不可选时间
    disabledDate (current) {
      // 默认大于今天的时间 小于2021.9
      return (current && current < this.initDate(getDuration(), 0).start) || current > this.initDate(0, 0).end
    },
    // 选择时间
    handleChangeTime (date, dateString) {
      this.rangePickerDate = dateString
      const [dateStart, dateEnd] = dateString
      this.querData.dateStart = dateStart
      this.querData.dateEnd = dateEnd
    },
    // 搜索
    handleSerach () {
      this.querData.page = 1
      this.getlist()
    },
    // 分页
    handleCurrentPage (page) {
      this.querData.page = page
      this.getlist()
    },
    handleChangePageSize (current, size) {
      this.querData.page = 1
      this.querData.limit = size
      this.getlist()
    }
  }
}
</script>
<style lang="less" scoped>
@import '~@/assets/less/mixins/redefineAnt.less';
.creative_item_wrapper {
  width: 200px;
  height: 100px;
  .el_image_box {
    background: #fafafa;
    // width: var(--ad--creative--width);
    // height: 120px;
    border: 1px dashed #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      .modal {
        opacity: 1;
      }
    }
    .modal {
      opacity: 0;
      background: rgba(0, 0, 0, 0.1);
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 200px;
      height: 100px;
      .deleteIcon {
        margin: 5px;
        cursor: pointer;
        color: #333;
        font-size: 20px;
        &:hover {
          color: @primary-color;
        }
      }
    }
  }
}
.m-t-20 {
  margin-top: 20px;
}
.m-r-10 {
  margin-right: 10px;
}
.m-b-10 {
  margin-bottom: 10px;
}
.p-0 {
  padding: 0;
}
.btnColor {
  color: #333;
}
::v-deep {
  .el-table__expand-column.el-table__cell {
    display: inline-block;
    width: 0;
    border: none;
    overflow: hidden;
  }
  .myCell .el-checkbox__input {
    display: none;
  }
  .el-table__empty-text {
    display: none;
  }
  .row-expand-cover .el-table__expand-icon {
    display: none;
  }
  .el-table .ascending .sort-caret.ascending {
    border-bottom-color: @primary-color;
  }
  .el-table .descending .sort-caret.descending {
    border-top-color: @primary-color;
  }
  .el-table__body .el-table__row.hover-row td {
    background-color: fade(@primary-color, 10%) !important;
  }
  .el-table .el-table__body tr.hover-row > td {
    background-color: fade(@primary-color, 10%) !important;
  }
  .el-tablle--enable-row-hover .el-table__body tr:hover > td {
    background-color: fade(@primary-color, 10%) !important;
  }
  .el-table__cell.el-table__expanded-cell {
    background: #f7f7f7;
    padding: 0;
    .el-table tbody tr td.el-table__cell {
      background: #f7f7f7;
    }
    .el-table tbody tr:hover > td {
      background-color: #f7f7f7 !important;
    }
    .el-empty {
      padding: 5px;
      background: #fff;
      .el-empty__image {
        height: 60px;
      }
      .el-empty__description {
        margin-top: 0;
      }
    }
  }
  .ant-modal {
    top: 22%;
    .ant-modal-body {
      .batchModel {
        padding: 30px;
        display: flex;
        align-items: center;
      }
      .icon {
        color: #fa8b15;
        font-size: 22px;
        margin-right: 20px;
      }
      p {
        margin-bottom: 0;
        line-height: 24px;
      }
    }
    .ant-modal-footer {
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
      border-top: none;
      padding: 14px;
    }
    .ant-btn,
    .ant-btn-primary {
      width: 90px;
    }
  }
}
::v-deep {
  .btnHover {
    border: none;
    box-shadow: none;
    &:hover {
      color: @primary-color;
      font-weight: 600;
      // background-color: #ecefff;
      // border-radius: 16px;
    }
  }
  .el-btn[disabled] {
    background: #fff;
  }
}
.advertisingPlan_container {
  padding: 20px;
  .search {
    .btn {
      background: fade(@primary-color, 10%);
      border: 1px solid @primary-color;
      color: @primary-color;
    }
  }
  .allianceSerachForm {
    display: flex;
    justify-content: space-between;
    position: relative;
    .statusBox {
      display: flex;
      flex-direction: row;
    }
  }
  .sonfig_tables {
    height: 100%;
    .action-slot{
      .btn{
        color: @primary-color;
        cursor: pointer;
      }
      .line{
        color: #ccc;
      }
      span{
        margin-right: 10px;
      }
    }
    .red {
      color: #f83366;
    }
    .green {
      color: #15ad31;
    }
    .grey {
      color: #999999;
    }
    .pagination {
      margin-top: 20px;
    }
  }
}
</style>
