import { message } from 'ant-design-vue'

/**
 *get
 *@param{object|array}obj数据源
 *@param{string}path路径
 *@param{*}defaultValue默认参数
 */
export function get (obj, path, defaultValue = undefined) {
  const travel = (regexp) =>
    String.prototype.split
      .call(path, regexp)
      .filter(Boolean)
      .reduce((res, key) => (res !== null && res !== undefined ? res[key] : res), obj)
  const result = travel(/[,[\]]+?/) || travel(/[,[\].]+?/)
  return result === undefined || result === obj ? defaultValue : result
}

/**
 *格式化时间
 *@param{*}dateTime
 *@param{*}format
 */
export function formatDate (dateTime, format) {
  const dateTimeLs = parseInt(dateTime)
  if (dateTimeLs === dateTime) dateTime = dateTimeLs
  const date = new Date(dateTime)
  let formatDate = format || 'yyyy-MM-dd hh:mm:ss'
  if (/(y+)/.test(formatDate)) {
    formatDate = formatDate.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
  }
  const o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds()
  }
  for (const k in o) {
    if (new RegExp(`(${k})`).test(formatDate)) {
      const str = o[k] + ''
      formatDate = formatDate.replace(RegExp.$1, RegExp.$1.length === 1 ? str : ('00' + str).substr(str.length))
    }
  }
  return formatDate
}
/**
 *二级制数据转text
 *@param{*}blob
 */
export const readerFile = (blob) => {
  const reader = new FileReader()
  return new Promise((resolve, reject) => {
    reader.onload = function (e) {
      resolve(reader.result)
    }
    reader.readAsText(blob, 'utf-8')
  })
}

/**
 *excel验证
 *@param{*}file
 */
export const validateExcel = (file) => {
  return new Promise((resolve, reject) => {
    if (/\.(?:xls|xlsx)$/.test(file.name.toLowerCase())) {
      resolve(file)
    } else {
      reject(new Error('只能上传 xls/xlsx 文件！'))
      message.error('只能上传 xls/xlsx 文件！')
    }
  })
}
